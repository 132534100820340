$(function() {
  var tableMember;

  tableMember = $('#members_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#members_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        d.member_name = $("#member_name").val();
        d.city_id = $("#city_id").val();
        d.branch_id = $("#branch_id").val();
        d.status = $("#status").val();
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "no"},
      {data: "name"},
      {data: "phone"},
      {data: "city"},
      {data: "branch"},
      {data: "status"},
      {data: "action"}
    ], order: [1, "asc"]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });

  $('#members_datatable_filter input').unbind();
  $('#members_datatable_filter input').bind('keyup', function(e) {
    if (e.keyCode === 13) {
      tableMember.fnFilter(this.value);
    }
  });

  window.filterMember = function() {
    tableMember.fnFilter("");
  }

  window.submit = function(add_credit = false) {
    if(add_credit) {
      $("#form_member").append("<input type='hidden' id='add_credit' name='member[add_credit]' value='true'>")
    } else {
      $("#form_member").append("<input type='hidden' id='add_credit' name='member[add_credit]' value='false'>")
    }

    $("#form_member").submit();
  }
});