$(function() {
  var tableWithdrawal;

  tableWithdrawal = $('#withdrawals_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#withdrawals_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        d.date1 = $("#date1").val();
        d.date2 = $("#date2").val();
        d.member_name = $("#member_name").val();
        d.branch_id = $("#branch_id").val();
        d.status = $("#status").val();
        d.user_id = $("#user_id").val();
        d.admin_id = $("#admin_id").val();
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "datetime"},
      {data: "member"},
      {data: "amount"},
      {data: "status"},
      // {data: "staff1"},
      {data: "branch"},
      {data: "staff"},
      {data: "action"}
    ], order: [0, "desc"]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });

  $('#withdrawals_datatable_filter input').unbind();
  $('#withdrawals_datatable_filter input').bind('keyup', function(e) {
    if (e.keyCode === 13) {
      tableWithdrawal.fnFilter(this.value);
    }
  });

  window.filterWithdrawal = function() {
    tableWithdrawal.fnFilter("");
  }
});