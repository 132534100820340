$(function() {
  var tableUser;

  tableUser = $('#users_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#users_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        d.branch_id = $("#branch_id").val();
        d.role = $("#role").val();
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "username"},
      {data: "name"},
      {data: "phone"},
      {data: "email"},
      {data: "role"},
      {data: "branch"},
      {data: "action"}
    ], order: [0, "asc"]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });

  $('#users_datatable_filter input').unbind();
  $('#users_datatable_filter input').bind('keyup', function(e) {
    if (e.keyCode === 13) {
      tableUser.fnFilter(this.value);
    }
  });

  window.filterUser = function() {
    tableUser.fnFilter("");
  }
});