$(function() {
  var tableCreditPayment;
  var tableCreditPayment2;

  tableCreditPayment = $('#credit_payments_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#credit_payments_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        d.date1 = $("#date1").val();
        d.date2 = $("#date2").val();
        d.no = $("#no").val();
        d.branch_id = $("#branch_id").val();
        d.status = $("#status").val();
        d.user_id = $("#user_id").val();
        d.admin_id = $("#admin_id").val();
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "datetime"},
      {data: "credit"},
      {data: "nik"},
      {data: "member"},
      {data: "credit_amount"},
      {data: "amount"},
      {data: "fee"},
      {data: "status"},
      {data: "branch"},
      // {data: "staff1"},
      {data: "staff"},
      {data: "action"}
    ], order: [0, "desc"]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });

  $('#credit_payments_datatable_filter input').unbind();
  $('#credit_payments_datatable_filter input').bind('keyup', function(e) {
    if (e.keyCode === 13) {
      tableCreditPayment.fnFilter(this.value);
    }
  });

  tableCreditPayment2 = $('#credit_payments2_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#credit_payments2_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        d.date1 = $("#date1").val();
        d.date2 = $("#date2").val();
        d.no = $("#no").val();
        d.branch_id = $("#branch_id").val();
        d.status = $("#status").val();
        d.user_id = $("#user_id").val();
        d.admin_id = $("#admin_id").val();
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "datetime"},
      {data: "credit"},
      {data: "amount"},
      {data: "fee"},
      {data: "status"},
      // {data: "staff1"},
      {data: "branch"},
      {data: "staff"},
      {data: "action"}
    ], order: [0, "desc"]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });

  $('#credit_payments2_datatable_filter input').unbind();
  $('#credit_payments2_datatable_filter input').bind('keyup', function(e) {
    if (e.keyCode === 13) {
      tableCreditPayment2.fnFilter(this.value);
    }
  });

  window.filterCreditPayment = function() {
    tableCreditPayment.fnFilter("");
  }

  window.setCreditPayment = function(elm, path) {
    window.location.href = path + "?nik=" + elm.value;
  }

  var el = document.getElementById("credit_payment_nik");
  el.addEventListener("keypress", function(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  });
});