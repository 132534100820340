$(function() {
  var tableCredit;

  tableCredit = $('#credits_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#credits_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        d.date1 = $("#date1").val();
        d.date2 = $("#date2").val();
        d.branch_id = $("#branch_id").val();
        d.no = $("#no").val();
        d.member_name = $("#member_name").val();
        d.status = $("#status").val();
        d.user_id = $("#user_id").val();
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "datetime"},
      {data: "no"},
      {data: "nik"},
      {data: "member"},
      {data: "amount"},
      {data: "instalment"},
      {data: "remain"},
      {data: "balance"},
      {data: "status"},
      {data: "branch"},
      {data: "user_staff"},
      {data: "action"}
    ], order: [0, "desc"]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });

  $('#credits_datatable_filter input').unbind();
  $('#credits_datatable_filter input').bind('keyup', function(e) {
    if (e.keyCode === 13) {
      tableCredit.fnFilter(this.value);
    }
  });

  var tableCredit2;

  tableCredit2 = $('#credits2_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#credits2_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "datetime"},
      {data: "no"},
      {data: "amount"},
      {data: "instalment"},
      {data: "remain"},
      {data: "balance"},
      {data: "status"},
      {data: "branch"},
      {data: "user_staff"},
      {data: "action"}
    ], order: [0, "desc"]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });

  $('#credits2_datatable_filter input').unbind();
  $('#credits2_datatable_filter input').bind('keyup', function(e) {
    if (e.keyCode === 13) {
      tableCredit2.fnFilter(this.value);
    }
  });

  window.filterCredit = function() {
    tableCredit.fnFilter("");
  }
});